import { useContext, useMemo } from 'react'

import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

import AuthorizationContext from '../../common/authorization/Context'

const query = gql`
  query {
    models(first: 100) {
      edges {
        node {
          id
          slug
          acf {
            thumbnailVip {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`

export const useVipModels = (modelsListDefault) => {
  const { isLoggedIn } = useContext(AuthorizationContext)

  const { data, loading } = useQuery(query, { skip: !isLoggedIn })

  const dataMap = useMemo(() => {
    if (data?.models?.edges) {
      return data.models.edges.reduce(
        (result, { node }) => ({
          ...result,
          [node.id]: {
            vipImage: node.acf.thumbnailVip?.mediaItemUrl,
          },
        }),
        {},
      )
    }
    return null
  }, [data])

  const modelsList = useMemo(() => {
    if (!dataMap) {
      return modelsListDefault
    }

    return modelsListDefault.map((item) => ({
      ...item,
      vipThumbnail: dataMap[item.id]?.vipImage,
    }))
  }, [modelsListDefault, dataMap])

  return { modelsList, loading }
}
