import { useMemo } from 'react'
import shuffle from 'lodash/shuffle'

import mapModelsToList from '../queries/mapModelsToList'

const UPDATE_PERIOD = 86400000

const isBrowser = () => typeof window !== 'undefined'

const useSaveModels = (models, prefix) => {
  // used useEffect because of SSR
  const modelsList = useMemo(() => {
    if (isBrowser()) {
      const modelsMap =
        models?.reduce(
          (result, model) => ({ ...result, [model.node.id]: model }),
          {},
        ) || {}

      const lastUpdate = localStorage.getItem(`${prefix}-modelsLastUpdate`)
      const modelsStillUpToDate = Date.now() - lastUpdate < UPDATE_PERIOD
      const modelsFromLocalStorage = JSON.parse(
        localStorage.getItem(`${prefix}-models`),
      )

      if (modelsStillUpToDate && modelsFromLocalStorage?.length) {
        const mappedModelsFromLocalStorage = mapModelsToList(
          modelsFromLocalStorage.map((id) => modelsMap[id]),
        )
        return mappedModelsFromLocalStorage
      }
      const shuffledModels = shuffle(models)
      const newModelsList = mapModelsToList(shuffledModels)
      const newModelIds = newModelsList.map(({ id }) => id)
      localStorage.setItem(`${prefix}-models`, JSON.stringify(newModelIds))
      localStorage.setItem(`${prefix}-modelsLastUpdate`, Date.now())
      return newModelsList
    }
    return models
  }, [models, prefix])

  return modelsList
}

export default useSaveModels
