import React, { useMemo } from 'react'
import { graphql } from 'gatsby'

import TruncatedHero from '../components/Heros/TruncatedHero/TruncatedHero'
import Container from '../components/Container'
import Inner from '../components/Inner'
import InfoBox from '../components/InfoBox/InfoBox'
import { Heading2, Heading7 } from '../components/Typography/Typography'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import ModelsList from '../components/ModelsList/ModelsList'
import NewsSlider from '../components/Sliders/NewsSlider/NewsSlider'
import FlexContentBlocks from '../common/queries/FlexContentBlocks'
import InternalLink from '../components/Buttons/InternalLink/InternalLink'
import { TRUNCATED_HERO_SLANT_TYPES } from '../constants/truncatedHeroTypes'
import { responsiveImage, bgTheme, replaceAtToSpan } from '../common/utils'
import { BG_TYPES } from '../constants/bgTypes'
import { URL_PATHS } from '../constants/urlPaths'
import { BUTTON_TYPES } from '../constants/buttonTypes'
import mapNewsToList from '../common/queries/mapNewsToList'
import useSaveModels from '../common/hooks/useSaveModels'
import HTMLBody from '../components/HTMLBody'
import { useVipModels } from '../common/hooks/useVipModels'

const CityBody = ({ data }) => {
  const { acf: city } = data.wp.city
  const { edges: models } = data.wp.city.models
  const { edges: posts } = data.wp.city.posts
  const allModels = data.wp.models.edges
  const currentCity = data.wp.city.slug
  const contentBlocks = city.contentBlocks.content

  // hero
  const { bgGradient } = city.heroFields
  const hasBgGradient = bgGradient && bgGradient !== 'none'
  const isHeroHigh = city.heroFields.heroHeight === 'long'
  const heroBgColorMobile = bgTheme(city.heroFields.bgMobile)
  const heroBgColorDesktop = bgTheme(city.heroFields.bgDesktop)
  const heroColorDesktop = heroBgColorDesktop === 'black' ? 'white' : 'black'
  const infoBoxOpacity = city.heroFields.boxBackgroundOpacity / 100
  const infoBoxBg = city.heroFields.boxBackgroundColor

  const heroImages = responsiveImage(
    city.heroFields.backgroundMobile,
    city.heroFields.backgroundDesktop,
  )

  const heroImagesNoModels = responsiveImage(
    city.heroFields.backgroundMobileNoModels,
    city.heroFields.backgroundDesktopNoModels,
  )

  const newAllModels = useMemo(
    () =>
      allModels?.filter((model) => !model.node.termSlugs.includes(currentCity)),
    [allModels, currentCity],
  )

  const modelsTop = useSaveModels(models, `availableIn${currentCity}`)
  const modelsBottom = useSaveModels(
    newAllModels,
    `ableToTravelTo${currentCity}`,
  )

  const { modelsList: modelsTopSection } = useVipModels(modelsTop)
  const { modelsList: modelsBottomSection } = useVipModels(modelsBottom)

  const hasModels = modelsTopSection?.length > 0
  const browseNews = mapNewsToList(posts)
  const modelsBg = bgTheme(city.newsletterContainerBackground)
  const newsBg = bgTheme(city.newsListBackground)

  return (
    <>
      <Container
        bg={[heroBgColorMobile, '', heroBgColorDesktop]}
        color={['white', '', heroColorDesktop]}
      >
        <TruncatedHero
          heroImages={hasModels ? heroImages : heroImagesNoModels}
          heroSlantType={
            hasModels
              ? TRUNCATED_HERO_SLANT_TYPES.right
              : TRUNCATED_HERO_SLANT_TYPES.left
          }
          gradient={hasBgGradient ? bgGradient : null}
          lightGradient={heroBgColorMobile === BG_TYPES.white}
          long={isHeroHigh}
          centered
        >
          <Container>
            <InfoBox
              title={
                <Heading2
                  as="h1"
                  dangerouslySetInnerHTML={{
                    __html: replaceAtToSpan(city.heroFields.infoboxTitle),
                  }}
                />
              }
              content={
                <HTMLBody opacity="1" html={city.heroFields.infoboxContent} />
              }
              bgOpacity={infoBoxOpacity}
              bg={infoBoxBg}
            />
          </Container>
        </TruncatedHero>
      </Container>
      <Container bg={modelsBg}>
        <Container className="uiAnimCustomBottom">
          {hasModels && (
            <Container pt={['3rem', '3.375rem']} pb={['2.5rem', '3.375rem']}>
              <Inner>
                <Container
                  display="flex"
                  textAlign={['center', 'left']}
                  mb="2rem"
                >
                  <Container mr={['', '2rem']}>
                    <Heading7
                      as="h2"
                      color="yellow"
                      dangerouslySetInnerHTML={{
                        __html: `Escort Models Available in ${data.wp.city.name}`,
                      }}
                    />
                  </Container>
                </Container>
                <Container mb={['2.5rem', '0']}>
                  <ModelsList models={modelsTopSection} anim={false} />
                </Container>
                <Container mt={['2.5rem', '3.75rem']} textAlign="center">
                  <InternalLink
                    to={URL_PATHS.MODELS}
                    btnType={
                      modelsBg === BG_TYPES.black || BG_TYPES.lightBlack
                        ? BUTTON_TYPES.secondary
                        : BUTTON_TYPES.inverted
                    }
                  >
                    Back to All Models
                  </InternalLink>
                </Container>
              </Inner>
            </Container>
          )}
        </Container>
      </Container>

      <FlexContentBlocks contentBlocks={contentBlocks} pageData={data} />

      <Container bg={modelsBg}>
        <Container className="uiAnimCustomBottom">
          {modelsBottomSection?.length > 0 && (
            <Container pt={['3rem', '3.375rem']} pb={['2.5rem', '3.375rem']}>
              <Inner>
                <Container mb="2.25rem" textAlign={['center', 'left']}>
                  <Heading7
                    as="h2"
                    color="yellow"
                    dangerouslySetInnerHTML={{
                      __html: `Escort models able to travel to ${data.wp.city.name}`,
                    }}
                  />
                </Container>
                <Container mb={['2.5rem', '3.75rem']}>
                  <ModelsList models={modelsBottomSection} anim={false} />
                </Container>
                <Container textAlign="center">
                  <InternalLink
                    to={URL_PATHS.MODELS}
                    btnType={
                      modelsBg === BG_TYPES.black || BG_TYPES.lightBlack
                        ? BUTTON_TYPES.secondary
                        : BUTTON_TYPES.inverted
                    }
                  >
                    Back to All Models
                  </InternalLink>
                </Container>
              </Inner>
            </Container>
          )}
        </Container>
      </Container>
      {!!browseNews && browseNews.length > 0 &&
        <Container
          pt={['3.375rem', '5rem']}
          pb={['2.875rem', '6.25rem']}
          bg={newsBg}
          overflow="hidden"
        >
          <Container className="uiAnimCustomBottom">
            <Inner>
              <Container mb={['2.5rem', '3.375rem']}>
                <Heading7 as="h3" color="yellow" textAlign="center">
                  Browse News
                </Heading7>
              </Container>
              <Container>
                <NewsSlider news={browseNews} />
              </Container>
            </Inner>
          </Container>
        </Container>
      }
    </>
  )
}

const CityTemplate = ({ data }) => {
  const seoData = data.wp.city.seo

  return (
    <Layout>
      <SEO title={seoData?.title} data={seoData} />

      <CityBody data={data} />
    </Layout>
  )
}

export default CityTemplate

export const pageQuery = graphql`
  query($cityId: ID!) {
    wp {
      city(id: $cityId, idType: ID) {
        id
        slug
        name
        posts(first: 15) {
          edges {
            node {
              ...postFragment
              termSlugs(taxonomies: CITY)
            }
          }
        }
        seo {
          ...seoFragment
        }
        acf {
          newsletterContainerBackground
          newsListBackground
          heroFields {
            bgGradient
            boxBackgroundOpacity
            boxBackgroundColor
            infoboxTitle
            infoboxContent
            heroHeight
            bgDesktop
            bgMobile
            backgroundDesktop {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            backgroundMobile {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 375) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            backgroundDesktopNoModels {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            backgroundMobileNoModels {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 375) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            action {
              showAction
              action {
                internalOrExternal
                external {
                  target
                  title
                  url
                }
                internal {
                  title
                  target {
                    ... on wp_Post {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_Page {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_Model {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                  }
                }
              }
            }
          }
          contentBlocks {
            content {
              ... on wp_City_Acf_ContentBlocks_Content_PictureAndContentBox {
                ...city_pictureBoxFragment
              }
              ... on wp_City_Acf_ContentBlocks_Content_Callout {
                ...city_calloutFields
              }
              ... on wp_City_Acf_ContentBlocks_Content_ModelsSlider {
                fieldGroupName
                bg
                isAlt
              }
              ... on wp_City_Acf_ContentBlocks_Content_OtherModelsSlider {
                fieldGroupName
                bg
                type
              }
              ... on wp_City_Acf_ContentBlocks_Content_GallerySlider {
                ...city_gallerySliderFields
              }
            }
          }
        }
        models(first: 50) {
          edges {
            node {
              id
              title
              slug
              featuredImage {
                mediaItemUrl
                imageFile {
                  childImageSharp {
                    fluid(
                      sizes: "(max-width: 767px) calc(100vw - 60px), 555px"
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              acf {
                ...modelDetailsFragment
                comingSoon
              }
              termSlugs(taxonomies: CITY)
            }
          }
        }
      }
      models(first: 50) {
        edges {
          node {
            id
            title
            slug
            featuredImage {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(sizes: "(max-width: 767px) calc(100vw - 60px), 555px") {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            acf {
              ...modelDetailsFragment
              comingSoon
            }
            termSlugs(taxonomies: CITY)
          }
        }
      }
    }
  }
`
