import styled from 'styled-components'

import media from '../../common/MediaQueries'

const StyledModelsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media ${media.phone} {
    margin: -1.875rem -0.9375rem 0 -0.9375rem;
    width: calc(100% + 1.875rem);
  }
`

const StyledModelsListItem = styled.li`
  display: flex;
  flex-direction: column;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 1.875rem;
  }

  @media ${media.phone} {
    width: calc(50% - 1.875rem);
    margin: 1.875rem 0.9375rem 0 0.9375rem;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
`

export { StyledModelsList, StyledModelsListItem }
