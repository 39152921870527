import React from 'react'
import PropTypes from 'prop-types'

import ModelItem from '../ModelItem/ModelItem'
import { StyledModelsList, StyledModelsListItem } from './styles'
import { fluidPropType } from '../../constants/proptypes'

const ModelsList = ({ models }) => (
  <StyledModelsList>
    {models.map(
      ({ to, name, description, details, image, comingSoon, vipThumbnail }) => (
        <StyledModelsListItem key={name}>
          <ModelItem
            {...{
              name,
              description,
              details,
              image,
              comingSoon,
              vipThumbnail,
              to,
            }}
          />
        </StyledModelsListItem>
      ),
    )}
  </StyledModelsList>
)

export default ModelsList

ModelsList.propTypes = {
  models: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: fluidPropType,
      details: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          data: PropTypes.string.isRequired,
        }),
      ),
      comingSoon: PropTypes.bool,
      vipThumbnail: PropTypes.string,
    }),
  ).isRequired,
}
